import BasicNavbar from './Navbar';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import { FaStar } from 'react-icons/fa';
import { BsFillHexagonFill } from 'react-icons/bs'
//import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PiBirdBold } from 'react-icons/pi';
import { SiBlueprint } from 'react-icons/si';
import { SiPrintables } from 'react-icons/si';
import { AiFillInstagram } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
import { SiMercadopago } from 'react-icons/si';


export function Home(){
    return (

    <div>
        <header className="headerBlack">
            <img src="/imgs/berd.png" alt="logo B3RD color bordo" width="50" />
            <p className="white marginBot noMarginBottom">¡Bienvenido a B3RD, tu mejor opción para tus juegos de mesa! </p>
        
        </header>
        <BasicNavbar />

        
        <main>
            <section id="banner">
            
            <div className="backgroundColors">
                <h2>HEXBOX</h2>
                <p className="white">Nos enorgullece presentarte nuestro producto estrella, <b>Hexbox</b></p> 

                <p className="white">La solución definitiva para los amantes de los juegos de mesa y D&D.</p>
            </div>
            <Carousel >
            <div>
                <img src="/imgs/todos.jpg" alt="4 Torres de dados HEXBOX, dos SG y dos BG, de diferentes colores"  className="carrouselImg" />
                <p className="legend">Vinimos a cambiar la forma de guardar tus dados</p>
                </div>
                <div>
                <img src="/imgs/cero.jpg" alt="HEXBOX portable en una mochila"  className="carrouselImg" />
                <p className="legend">Totalmente portable</p>
                </div>
                <div >
                <img src="/imgs/five.jpg" alt="HEXBOX en modo torre de dados" className="carrouselImg" />
                <p className="legend">Se desarma y transforma en torre de dados</p>
                </div>
                <div>
                <img src="/imgs/products-tsm-1.jpg" alt="HEXBOX desarmada y desplegada mostrando su almacenamiento" className="carrouselImg" />
                <p className="legend">Gran capacidad de almacenamiento y organización</p>
                </div>
            </Carousel>
            </section>
            <section id="features" className="hexagon">
            <p className="star"><small><BsFillHexagonFill /></small> </p>
            <h2 className="caractTitle">Características Destacadas</h2>
            <div >
                
                <div className="dualBox">
                <img src="/imgs/caract_1.jpg" alt="HEXBOX desarmada y desplegada mostrando su almacenamiento sobre una mesa" className="caractImage hexagon" />
                
                </div>

                <div className="dualBox textbox">
                <div className="feature caractParraph">
                <p className="center"><BsFillHexagonFill /></p>
                    <h3>Tu caja de dados y torre de dados, en uno</h3>
                    <p>Hexbox es mucho más que una simple caja de dados; es una revolución en la forma en que almacenas, transportas y juegas tus juegos de mesa favoritos. </p>
                    <p>Esta caja de dados se transforma instantáneamente en una impresionante torre de dados, brindándote una experiencia de juego única.</p>
                </div>

                <div className="feature caractParraph">
                <p className="center"><BsFillHexagonFill /></p>
                    <h3>La solución perfecta para tus dados</h3>
                    <p>¿Cansado de perder tus dados? Con Hexbox, eso es cosa del pasado. Nuestra caja de dados cuenta con un cierre magnético seguro que mantendrá tus dados a salvo en todo momento. ¡Ya no tendrás que preocuparte por esos dados perdidos!</p>
                </div>

                <div className="feature caractParraph">
                    <p className="center"><BsFillHexagonFill /></p>
                    <h3>Dos tamaños para adaptarse a tus necesidades</h3>
                    <p>Entendemos que cada jugador es único. Es por eso que ofrecemos Hexbox en dos tamaños: pequeño y grande. Elige el que mejor se adapte a tus necesidades y lleva tus juegos de mesa al siguiente nivel.</p>

                    
                </div>

                
                </div>
                <div className="fifty">
                    <img src="/imgs/caract_3.jpg" alt="HEXBOX dos tamaños, color blanco y azul" className="caractImage hexagon" />
                </div>
                <div className="fifty">
                    <img src="/imgs/caract_4.jpg" alt="HEXBOX dos tamaños, color rojo y negro" className="caractImage hexagon" />
                </div>
                
            </div>
            <div className="feature caractParraph outside">
                    
                    <h3>¿Estás listo para mejorar tu experiencia en los juegos de mesa?</h3>
                
                    <p>Descubrí Hexbox y transforma la forma en que jugas. No pierdas la oportunidad de hacer que tus aventuras sean aún más emocionantes.</p>

                    <p className=""><span>¡Utiliza este cupon para obtener un <b className="text text-success">15% de descuento</b> en tu primera compra!</span></p>
                    <p className="">CUPON 15% DESCUENTO: </p>
                    <p><span className="alert alert-success">FIRSTB3RD</span></p>
                    <a className="marginAll btn btn-success" href="/productos" >VER MODELOS EN PROMOCIÓN</a>
                </div>
            
            
            </section>
            <section id="whoweare" className="hexagon white">
            <p className="star"><small><BsFillHexagonFill /></small> </p>
            <p className="circle"> <img src="/imgs/berd.png" alt="logo b3rd" width="35" /></p>
            <h2 className="paddingLow">¿Quienes somos?</h2>
            <div className="center paddingBottom">
                <div className="paddingLow">
                
                <p className="markerIcon"><b className="f20"><PiBirdBold /></b> </p>
                <p>Somos B3RD, una de las pocas start-ups argentinas que apuestan por el rubro de los juegos de mesa.</p> <p>Nuestro nombre viene de la combinación de la impresion 3d que utilizamos para dar vida a nuestros productos, y la palabras palabras "Beyond Reality".</p><p> Ambos conceptos combinados dan vida a nuestro emprendimiento.</p>
                </div>
                <div className="paddingLow">
                <p className="markerIcon"><b className="f20"><SiPrintables /></b> </p>
                
                <p>Nuestra marca vende productos impresos enteramente en 3d, pulidos y modificados para brindar calidad en todos nuestros componentes.</p>
                </div>
                <div className="paddingLow">
                <p className="markerIcon"><b className="f20"><SiBlueprint /></b> </p>
                <p>Lanzamos nuestros primeros productos (las Hexbox) a finales del 2023.</p>
                </div>
            </div>
            
            </section>
            
        </main>
        <footer>
        <section id="contact" >
                <p className="star"><small><BsFillHexagonFill /></small> </p>
                <h2>Nuestras redes</h2>

                
                <ul className="rrssList">
                    <li><a href="https://www.instagram.com/b3rd_ar/" className="rrss" target="_blank" rel="noreferrer"><AiFillInstagram /> <small>Instagram </small></a></li>
                    <li><a href="https://shorturl.at/tSX27" className="rrss" target="_blank" rel="noreferrer"><IoLogoWhatsapp /> <small>Whatsapp</small></a></li>
                    <li><a href="https://articulo.mercadolibre.com.ar/MLA-1393425489-caja-de-dados-y-torre-de-dados-hexbox-juegos-de-mesa-rol-dd-_JM?vari&_ga=2.244216581.1010181257.1698442507-356688103.1698442507&_ga=ML1.1698444646740.356688103.1698442507" className="rrss" target="_blank" rel="noreferrer"><SiMercadopago /> <small>Mercadolibre</small></a></li>
                </ul>
                <hr className="white"/>
            
            </section>
            <p>&copy; {new Date().getFullYear()} B3RD C.O.</p>
        </footer>
    </div>
);}