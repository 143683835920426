import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsFillHexagonFill } from 'react-icons/bs'

function BasicNavbar(){
    return (
        <Navbar expand="md" className="bg-body-tertiary">
        <Container>
            <Navbar.Brand href="/"><h1><span className="title">B3RD</span></h1></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="/#features"><BsFillHexagonFill /> Caracteristicas</Nav.Link>
                <Nav.Link href="/productos" ><BsFillHexagonFill/> Productos</Nav.Link>
                <Nav.Link href="/#contact"><BsFillHexagonFill /> Contactanos</Nav.Link>
                <Nav.Link href="/#whoweare"><BsFillHexagonFill /> ¿Quienes somos?</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default BasicNavbar;