
import './App.css';
import { render } from "react-dom";
import { Routes, Route, BrowserRouter,useNavigate } from 'react-router-dom';
import { Home } from './components/Home';
import { Products } from './components/Products';





function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<Products />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
