import BasicNavbar from './Navbar';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { BsFillHexagonFill } from 'react-icons/bs'

import 'bootstrap/dist/css/bootstrap.min.css';

import { AiFillInstagram } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
//import { SiMercadopago } from 'react-icons/si';


export function Products(){
    return (

    <div>
        <BasicNavbar/>
        
        <header className="banner">
            <img src="/imgs/berd.png" alt="logo B3RD color bordo" width="50" />

            <ul className="white">
                <li className="prodList"><a href="#hexsm"><BsFillHexagonFill /> Hexbox T-SM (chica)</a></li>
                <li className="prodList"><a href="#hexbg"><BsFillHexagonFill /> Hexbox T-BG (grande)</a></li>
            </ul>

            <p className="star paddingTop"><small><BsFillHexagonFill /></small> </p>
            <h1 className="white pdBt" id="hexsm">Hexbox T-SM</h1>
        
        </header>

        <div>
            

            <Carousel width="50" >
                
                    <div>
                        <img src="/imgs/products-tsm-2.jpg" alt="HEXBOX chica desplegada, color rojo y negro"  className="imgProd" />
                        
                    </div>
                    <div >
                        <img src="/imgs/products-tsm-3.jpg" alt="HEXBOX chica desplegada, color azul y blanco" className="imgProd" />
                        
                    </div>
                    <div>
                        <img src="/imgs/halfblue_small.jpg" alt="HEXBOX chica color rojo y negro replegada sobre su base de dados"  className=" imghalf" />
                        <img src="/imgs/halfred_small.jpg" alt="HEXBOX chica color azul y blanco replegada sobre su base de dados"  className=" imghalf" />
                        
                    </div>

                    
                    
            </Carousel>
            <div className="leyenda"> 
                <p>🎲¡Descubrí la innovación en almacenamiento de dados con Hexbox T-SM! 🎲
                    <br/><br/>
                    🌟Transforma tu experiencia de juego con nuestra caja hexagesimal única que se convierte en una increíble torre de dados.  <br/>
                    ¡El complemento perfecto para tus partidas de rol!
                    <br/><br/>
                    <u>Medidas:</u> <br/>📏 16 cm de alto <br/>📐 10.5 cm de ancho
                    <br/>
                    🎲✨ Almacena más de 45 dados variados y 3 bahias de juego de forma segura y elegante. 
                    <br/><br/>
                    Ideal para jugadores apasionados y coleccionistas de dados. <br/>🔮 ¡Lleva tus aventuras al siguiente nivel con estilo!
                    <br/><br/>

                    <u>Colores disponibles:</u> <br/>
                        <BsFillHexagonFill className="negro" /> <BsFillHexagonFill className="rojo" /> Negro y rojo <br/>
                        <BsFillHexagonFill className="blanco" /> <BsFillHexagonFill className="azul" /> Blanco y azul<br/>
                    <br/>

                    El pack de lanzamiento incluye: <br/>
                    <ul>
                        <li><small className="chibi"><BsFillHexagonFill /></small> 1 Hexbox T-SM completa</li>
                        <li><small className="chibi"><BsFillHexagonFill /></small> 3 Bahías externas de juego (de 5 slots)</li>
                        <li><small className="chibi"><BsFillHexagonFill /></small> 1 Base de torre universal (encaja con todas nuestras Hexbox)</li>
                    </ul>
                    <br/><br/>
                    Ordena la tuya ahora y transforma tu experiencia de juego. 🛍️✨

                    <a className="marginAll btn btn-success bigbtn" href="https://articulo.mercadolibre.com.ar/MLA-1393425489-caja-de-dados-y-torre-de-dados-hexbox-small-juegos-de-mesa-_JM" target="_blank" rel="noreferrer">Ver oferta</a>
                </p>

            </div>

            
        </div>

        <header className="banner">
            <p className="star"><small><BsFillHexagonFill /></small> </p>
            <h1 className="white pdBt" id="hexbg">Hexbox T-BG</h1>
        
        </header>

        <div>
            

            <Carousel width="50" >
                <div>
                    <img src="/imgs/products-tbg-1.jpg" alt="HEXBOX grande desplegada, color rojo y negro"  className="imgProd" />
                    
                </div>
                <div>
                    <img src="/imgs/products-tbg-2.jpg" alt="HEXBOX grande desplegada, color azul y blanco"  className="imgProd" />
                        
                </div>

                <div>
                    <img src="/imgs/halfblue_big.jpg" alt="HEXBOX grande replegada, color azul y azul"  className=" imghalf" />
                    <img src="/imgs/halfred_big.jpg" alt="HEXBOX grande replegada, color rojo y negro"  className=" imghalf" />
                    
                </div>
                    
                    
            </Carousel>

            <div className="leyenda"> 
                        <p>🎲¿Necesitas mas almacenamiento? ¡Nuestra Hexbox T-BG es para vos! 🎲
                        <br/><br/>
                        🌟Duplica tu capacidad de almacenamiento y la longitud de la torre de dados con la version BG de nuestas Hexbox<br/>
                        
                        <br/>
                        <u>Medidas:</u> <br/>📏 20.5 cm de alto <br/>📐 10.5 cm de ancho
                        <br/>
                        🎲✨ Almacena más de 90 dados variados, y 3 bahias de juego de forma segura, comoda y elegante. 
                        <br/><br/>
                        Ideal para jugadores apasionados y coleccionistas de dados. <br/>🔮 ¡Lleva tus aventuras al siguiente nivel con estilo!
                        <br/><br/>

                        <u>Colores disponibles:</u> <br/>
                                <BsFillHexagonFill className="negro" /> <BsFillHexagonFill className="rojo" /> Negro y rojo <br/>
                                <BsFillHexagonFill className="blanco" /> <BsFillHexagonFill className="azul" /> Blanco y azul<br/>
                            <br/>

                        El pack de lanzamiento incluye: <br/>
                        <ul>
                            <li><small className="chibi"><BsFillHexagonFill /></small> 1 Hexbox T-BG completa</li>
                            <li><small className="chibi"><BsFillHexagonFill /></small> 3 Bahías externas de juego (de 7 slots)</li>
                            <li><small className="chibi"><BsFillHexagonFill /></small> 1 Base de torre universal (encaja con todas nuestras Hexbox)</li>
                        </ul>
                        <br/>
                        ✨¡No esperes mas y ordena la tuya ahora! 🛍️✨

                        <a className="marginAll btn btn-success bigbtn" href="https://articulo.mercadolibre.com.ar/MLA-1395382901-caja-de-dados-y-torre-de-dados-hexbox-grande-juegos-de-mesa-_JM" target="_blank" rel="noreferrer">Ver oferta</a>
                        </p>

                    </div>

            <div >
                        
                        <div className="leyenda"> 
                            <h2>🎲¡ Consultanos por todos nuestros colores y modelos!🎲</h2>
                            <hr/>
                            <div>
                                <ul className="iblock">
                                    <li><a href="https://www.instagram.com/b3rd_ar/" className="rrss iblock" target="_blank" rel="noreferrer"><AiFillInstagram /> <small>Instagram </small></a></li>
                                    <li><a href="https://shorturl.at/tSX27" className="rrss iblock" target="_blank" rel="noreferrer"><IoLogoWhatsapp /> <small>Whatsapp</small></a></li>
                                </ul>

                            </div>

                        </div>
                        
                    </div>

            
        </div>
    </div>
);}